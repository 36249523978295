<template>
    <div class="j_push">
        <img src="../assets/jPush.png" alt="" style="width = 200px">
    </div>
</template>
<script>
export default {
    name: "JPush",
    components: {

    },
    data() {
        return {
        };
    },
}
</script>
<style lang="scss">
.j_push {
    margin-top: 40px;
    img {
        width: 100%;
    }
}
</style>
